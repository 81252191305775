import {useEffect, useState} from "react";
import {LoadingScreen, Header} from "../../viridis-ui";
import {getProjectStats} from "../../ApiClient";
import {useErrorBoundary} from "react-error-boundary";
import {Grid, Card, Typography, CardContent} from "@mui/material";

export default function Stats() {
    const [stats, setStats] = useState<ProjectStats | undefined>(undefined)
    const {showBoundary} = useErrorBoundary()
    useEffect(() => {
        getProjectStats()
            .then((res) => {setStats(res)})
            .catch((e) => showBoundary(e))
    }, [])

    if (stats) {
        const firstRow:StatInfo[] = [
            {value: stats.projectCounts.approved, title:"Approved Projects"},
            {value: stats.projectCounts.pending, title:"Pending Projects"},
            {value: stats.projectCounts.total, title:"Total Projects"},
        ]
        const cats = Object.keys(stats.categoryCounts)
        const catCount = Object.values(stats.categoryCounts) as unknown as number[]
        const secondRow:StatInfo[] = [
            {value: stats.studentsCount, title:"Students"},
            {value: catCount[0], title:cats[0]},
            {value: catCount[1], title:cats[1]}
        ]
        const thirdRow:StatInfo[] = []
        for (let i=2; i<5; i++) {
            thirdRow.push({value: catCount[i], title:cats[i]})
        }
        const fourthRow:StatInfo[] = []
        for (let i=5; i<8; i++) {
            fourthRow.push({value: catCount[i], title:cats[i]})
        }

        return (
        <Grid
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={2}
            mb={3}
            mt={5}
        >
            <Header variant={'h3'} divider color={'secondary.dark'}>
                Statistics
            </Header>
            <Grid
                mt={3}
                container xs={10}
                direction={'column'}
                alignItems={'flex-start'}
                justifyContent={'center'}
                spacing={1}
            >
                <TileRows info={firstRow}/>
                <TileRows info={secondRow}/>
                <TileRows info={thirdRow}/>
                <TileRows info={fourthRow}/>
            </Grid>
        </Grid>
        )
    }
    else {
        return <LoadingScreen/>
    }
}

interface StatInfo {
    value: any
    title: string
    blurb?: string
}
function StatTile({value, title}:StatInfo) {
    return (
        <Card variant={'outlined'}>
            <CardContent>
                <Typography textAlign={'center'} variant={'h6'} noWrap>{title}</Typography>
                <Header variant='h4' color={'black'}>{value}</Header>
            </CardContent>
        </Card>
    )
}

function TileRows({info}:{info:StatInfo[]}) {
    return (
        <Grid container item direction={'row'} alignItems={'center'} spacing={1}>
            {info.map((i) => (
                <Grid item xs={12/info.length}>
                    <StatTile value={i.value} title={i.title} />
                </Grid>
            ))}
        </Grid>
    )
}
