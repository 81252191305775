import {Box, Button, Grid,  TextField, Typography, Snackbar, IconButton, Alert} from "@mui/material";
import {useState, PropsWithChildren, useEffect, useRef} from "react";
import ConfirmDecision from "./ConfirmDecision";
import {Header} from "../Typography/Header";
import CloseIcon from '@mui/icons-material/Close'
import {useErrorBoundary} from 'react-error-boundary'

interface FormProps {
    header: string | undefined
    inputs: Array<{key:string, name:string}>
    apiCall?: Function
    callBack?: Function
    handleSuccess: Function
    specialInputs: {state: any, component:any, key:string}[] | []
    confDialog?: { title: string, text:string }
    noBorder?: boolean,
    buttonContained?: boolean
}
// Abstract Form component, from given API call internally handles FailRes or Error and passes back a SuccessRes
export default function Form(props:PropsWithChildren<FormProps>) {
    const {showBoundary} = useErrorBoundary()
    const [message, setMessage] = useState<string>('')
    const [openDialog, setOpen] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)
    const btn = useRef<null| HTMLButtonElement>(null)
    useEffect(() => {
        const enterWatch = (e:KeyboardEvent) => {
            if (e.key === 'Enter') {
               btn.current?.click()
            }
        }
        window.addEventListener("keydown", enterWatch);
        return () => {
            document.removeEventListener("keydown", enterWatch);
        }
    }, [])

    // form submission logic, will trigger a given api call or callback function
    const handleSubmit = async() => {
        // close the dialog (if opened)
        setOpen(false)

        let data = {}
        let valid = true
        console.log(stateList)
        // loops through the list of value -states and checks for invalid options
        for (let i of stateList) {
            if ((i.value === '' || i.value === null || i.value=== undefined) && valid) {
                valid = false
            }
            // in the data object, links the given key in the state-list to the value
            // @ts-ignore
            data[i.key] = i.value
        }
        // if an api call is specified, make the call and check for the standard responses
        if (valid && props.apiCall) {
            try {
                let res = await props.apiCall(data)
                if (res['success']) {
                    props.handleSuccess(res)
                }
                else {
                    setMessage(res['reason'])
                }
            }
            catch (e) {
                showBoundary(e)
            }
        }
        // if a callback function was given instead of an api call, pass the data object to it
        else if (valid && props.callBack) {
            props.callBack(data)
        }
        // valid was false, trigger the alert
        else{
            setMessage("An input is invalid, please correctly fill all fields")
            setAlertOpen(true)
        }
    }
    let formSx;
    // borderless styling, configured for the implementation in the accounts page
    if (props.noBorder) {
        formSx = {
            display: 'flex', flexDirection: 'column', width: '35%', height: 'auto',
            minWidth:600, mb:2
        }
    }
    // default styling
    else {
        formSx = {
            mt:0, mb: 2, display: 'flex', flexDirection: 'column', width: '35%', height: 'auto',
            border: 2, p: 6, borderRadius: 10, borderColor: 'primary.dark', minWidth:600
        }
    }
    // if true, a pop-up confirmation dialog appears before submission
    let confDialog;
    if (props.confDialog){
        confDialog = <ConfirmDecision
            successCallback={handleSubmit}
            cancelCallback={() => {setOpen(false)}}
            title={props.confDialog.title} text={props.confDialog.text}
            open={openDialog}
                        />
    }
    else confDialog = undefined
    // handles submission based on if its through the dialog or not
    const triggerSubmit = async () => {
        if (props.confDialog) {
            setOpen(true)
        }
        else {
            await handleSubmit()
        }
    }

    // dynamically creates inputs based on given config props
    let fieldList = []; // list of components
    // the key goes in the data object created on submission, the value is the components value state
    let stateList: {value:string, key:string}[] = [];
    // used by the dynamic text field component
    const collectVal = (value:string, key:string) => {
        stateList.push({value:value, key:key})
    }
    // generates dynamic text field inputs from the given array
    for (let i of props.inputs) {
        let key = i.key
        let name = i.name
        fieldList.push(<Grid item><DynamicTextField callBack={collectVal} label={name} dictKey={key} /></Grid>)
    }
    //adds the special inputs to the component list and adds their keys/values to the state list
    if (props.specialInputs) {
        for (let i of props.specialInputs) {
            // @ts-ignore
            fieldList.push(
                <Grid item><Grid container item alignItems={'center'} justifyContent={'center'}>
                    {i.component}
                </Grid></Grid>
            )
            stateList.push({value:i.state, key:i.key})
        }
    }
    let header;
    if (props.header) {
        header = <Header
                    color={'primary.main'}
                    fontWeight={'bold'}
                    mb={0} mt={0}
                    variant={'h3'}
                    textAlign={'center'}
                >
                    {props.header}
                </Header>
    }
    else {
        header = undefined
    }
    let buttonVariant: "contained" | "outlined"; // typing needed to appease ts compiler when giving the prop
    if (props.buttonContained) {
        buttonVariant = "contained"
    }
    else {
        buttonVariant = "outlined"
    }
    return (
        <Box sx={{flexGrow: 1}}>
            <Grid container direction={'column'} spacing={2} justifyContent={'center'} alignItems={'center'}>
                {/* content root */}
                <Grid item mb={3}>
                    {header}
                </Grid>
                <Grid item mb={1.5}><Typography variant={'h5'} color={'error'}>{message}</Typography></Grid>
                <Grid container direction={'column'} item xs={8} spacing={3}
                      sx={formSx}
                >
                    {fieldList}
                    <Grid container item alignItems={'center'} justifyContent={'center'} direction='column'>
                        <Grid item>
                            <Button
                                variant={buttonVariant}
                                onClick={() => triggerSubmit()}
                                size={'large'} sx={{width: 300, mt:3}}
                                ref={btn}
                            >
                                <Typography variant={'h5'} component={'h4'}>Submit</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            {props.children}
                        </Grid>
                    </Grid>
                </Grid>
                {confDialog}
                <Message alertOpen={alertOpen} setAlertOpen={setAlertOpen} severity="error" message={message} />
            </Grid>
        </Box>
    )
}

function DynamicTextField(props: {callBack:(value:string, key:string)=>void, label:string, dictKey:string}) {
    const [value, setValue] = useState<string>('')
    props.callBack(value, props.dictKey)
    return(
        <Grid container item alignItems={'center'} justifyContent={'center'} spacing={0}>
            <Grid item>
                <TextField
                    fullWidth
                    variant={'outlined'}
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value)
                    }}
                    label={props.label}
                    sx={{mb:2, width:500}}
                />
            </Grid>
        </Grid>
    )

}

interface MessageProps {
    severity: "error" | "warning" | "info" | "success"
    message: string,
    alertOpen: boolean,
    setAlertOpen: Function
}
function Message(props:MessageProps) {

    function Closer() {
        return (
            <IconButton
                size="small"
                color="inherit"
                onClick={() => props.setAlertOpen(false)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        )
    }

    return (
        <Snackbar
                open={props.alertOpen}
                onClose={() => {props.setAlertOpen(false)}}
                anchorOrigin={{vertical:'top', horizontal:'center'}}
        >
            <Alert variant={'filled'} severity={props.severity} action={<Closer />}>
                <Typography>{props.message}</Typography>
            </Alert>

        </Snackbar>
    )
}
