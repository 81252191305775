import {Grid, Typography} from "@mui/material";
import {Header} from "../viridis-ui";


export default function About() {
    return (
        <Grid
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={2}
            mb={3}
            mt={5}
        >

            <Header variant={'h3'} divider color={'secondary.dark'}>
                About the Fair
            </Header>
            <Grid
                mt={3}
                container xs={9}
                direction={'column'}
                alignItems={'flex-start'}
                justifyContent={'center'}
                spacing={0}
            >
                <Grid item mt={1.5}>
                    <Header variant={'h4'} color={'secondary.dark'}>
                        What is the CS Fair?
                    </Header>
                </Grid>
                <Grid item ml={2} mt={1}>
                    <Typography variant={'subtitle1'} color={'white'}>
                        The CS Fair is a UVM event where Computer Science students
                        (majors, minors or just taking a class) may submit and showcase
                        their computer projects. The purpose of the CS Fair is to give
                        students a chance to present their work to their peers and professionals
                        in an open and exciting environment. A panel of judges critique the student
                        work where the prize amounts are up to $300 with many opportunities to win.
                        This year the CS Fair will take place in the Davis Center in the Grand Maple Ballroom.
                    </Typography>
                </Grid>
                <Grid item mt={2.5}>
                    <Header variant={'h4'} color={'secondary.dark'}>
                        When and Where?
                    </Header>
                </Grid>
                <Grid item ml={2} mt={1} >
                    <Typography variant={'subtitle1'} color={'white'}>
                        December 8th, 1:10 to 4:20
                        <br/>
                        The Grand Maple Ballroom, Davis Center
                        <br />
                        590 Main St, Burlington, VT 05401
                    </Typography>
                </Grid>
                <Grid item mt={2.5}>
                    <Header variant={'h4'} color={'secondary.dark'}>
                        Origins
                    </Header>
                </Grid>
                <Grid item ml={2} mt={1} >
                    <Typography variant={'subtitle1'} color={'white'}>
                        The idea for the CS Fair was born in Robert Erickson's head while
                        hanging out in the students' club room sometime in 2012. After asking
                        several questions about why their projects were not working, Robert
                        commented on what cool projects they were. Robert's mind chewed on the
                        fact that it would be cool if other people besides this small group had
                        a chance to see these awesome projects.
                        <br/> <br/>
                        Robert said if he worked on creating a fair of some kind so they could
                        demonstrate their projects to a larger audience, would they be interested?
                        Everyone thought that would be great.
                            <br/> <br/>
                        Shortly after meeting with the students, at a CS faculty meeting,
                        then department chair Professor Maggie Epstein asked if someone
                        would be interested in organizing a student project demonstration
                        of some kind. Before she finished her sentence, Robert's hand was
                        up. Maggie gave Robert the go ahead and approved all of his crazy
                        ideas, thus the CS Fair was born!
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
