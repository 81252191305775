import {Form} from '../../viridis-ui'
import {Grid, TextField, Select, Typography, MenuItem, Button} from '@mui/material'
import {createProject, getCats} from "../../ApiClient";
import {Header} from "../../viridis-ui";
import {useEffect, useState} from 'react'
import {useAuth} from "../../hooks/useAuth";
import {useErrorBoundary} from "react-error-boundary";

interface Category {
    id: number
    name: string
}
export default function CreateProject() {
    const [message, setMessage] = useState<null| string>(null)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState<string>('')
    const [groupSize, setGroupSize] = useState(1)
    const [category, setCategory] = useState<string>()
    const [timeslot, setTimeslot] = useState<1 | 2| 3>(1)
    const [studentInfo, setSI] = useState<string>("")
    const [success, setSuccess] = useState(false)
    const {auth} = useAuth()
    const {showBoundary} = useErrorBoundary()
    const [cats, setCats] = useState<Category[]>([])
    useEffect(() => {
        getData()
    }, [])
    const getData = () => {
        getCats().then((res)=> {
            //@ts-ignore
            setCats(res)
        })
        .catch((e) => {showBoundary(e)})
    }
    interface FormData {
        title: string
        students: string
        description: string
        groupSize: number
        category: string
        timeslot: 1 | 2 | 3
    }
    const handleSubmit = async () => {
        let tslabel = "1:10-2:00"
        if (timeslot === 1) {
            tslabel = "1:10-2:00"
        }
        else if (timeslot === 2) {
            tslabel = "2:20-3:10"
        }
        else if (timeslot === 3) {
            tslabel = "3:30-4:20"
        }
        let reqBody = {
            title: title,
            studentInfoList: studentInfo.split(','),
            description: description,
            groupSize: groupSize,
            year: new Date().getFullYear(),
            category: category,
            postingNetid: auth.netid,
            studentInfoRaw: studentInfo,
            timeslot: tslabel
        }
        console.log(reqBody)
        try {
            //@ts-ignore
            let res = await createProject(reqBody)
            if (res['success']) {
                setSuccess(true)
            }
            else {
                setMessage(res['reason'])
            }
        }
        catch (e) {
            showBoundary(e)
        }
    }
    if (success) {
        return (
        <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'}>
            <Typography variant={'h2'}>Project submitted successfully! Awaiting admin approval</Typography>
        </Grid>
        )
    }
    else {
        let catDisplay = []
        for (let c of cats) {
            catDisplay.push(<MenuItem value={c.name}>{c.name}</MenuItem>)
        }
        return (
            <Grid
                mt={5} container xs={10}
                width={'100%'} direction={'column'}
                alignItems={'center'} justifyContent={'flex-start'}
                spacing={1}
                sx={{border: 3, borderColor: 'secondary.dark', borderRadius: 3}}
            >
                <Grid container item mb={5} alignItems={'center'} justifyContent={'center'}>
                    <Header variant={'h3'} color={'secondary.dark'}>Register Project</Header>
                </Grid>
                <Grid
                    container xs={8} direction={'column'}
                    alignItems={'flex-start'} justifyContent={'flex-start'}
                    spacing={2.5} mb={4} sx={{backgroundColor: 'rgba(256, 256, 256,.9)', borderRadius: 3}} pb={3} pr={3}
                >
                    <Grid item xs={8}>
                        <Typography variant={'h4'} color={'error'}>{message}</Typography>
                    </Grid>
                    <Grid item xs={8} minWidth={'20vw'}>
                        <TextField
                            name={"title"}
                            label={"Project Title"}
                            fullWidth sx={{color: 'secondary.dark'}}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={8} minWidth={'50vw'}>
                        <TextField
                            fullWidth multiline
                            minRows={8} maxRows={8}
                            label={"Description"}
                            name={'description'}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={8} minWidth={'20vw'}>
                        <TextField
                            select name={'category'}
                            label={'Category'} fullWidth
                            value={category}

                            onChange={(e) => {
                                //@ts-ignore
                                setCategory(e.target.value)
                            }}
                        >
                            {catDisplay}
                        </TextField>
                    </Grid>
                    <Grid item xs={8} minWidth={'20vw'}>
                        <TextField
                            select name={'timeslot'}
                            label={'Time Slot'} fullWidth
                            value={timeslot}
                            onChange={(e) => {
                                //@ts-ignore
                                setTimeslot(e.target.value)
                            }}
                        >
                            <MenuItem value={1}>1:10 - 2:00</MenuItem>
                            <MenuItem value={2}>2:20 - 3:10</MenuItem>
                            <MenuItem value={3}>3:30 - 4:20</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={8} minWidth={'20vw'}>
                        <TextField label={'Group Size'}
                                   name={'groupSize'} type={'number'}
                                   value={groupSize}
                            //@ts-ignore
                                   onChange={(e) => setGroupSize(e.target.value)}
                        />
                    </Grid>
                    <Grid item mt={2} xs={8}>
                        <Typography variant={'h6'}>
                            Enter names and emails of your group in the format FirstName1 LastName1, Email1,
                            FirstName2 LastName2, Email2
                        </Typography>
                    </Grid>
                    <Grid item xs={8} minWidth={'50vw'}>
                        <TextField
                            label={'Group Info'}
                            name={'students'}
                            fullWidth multiline
                            minRows={4} maxRows={4}
                            value={studentInfo}
                            onChange={(e) => {
                                setSI(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container item mb={4} alignItems={'center'} justifyContent={'center'}>
                    <Typography variant={'h5'}>Registration is Closed!</Typography>
                </Grid>
            </Grid>
        )
    }
}
