import {Header} from "../viridis-ui";
import {Grid, Typography, Paper, Box, Card, CardContent,CardMedia, CardActionArea} from "@mui/material";
import CemsLogo from '../assets/csFair_sponsor_logos/2017_Sec_Logos_EngMath_H_K.png'
import EricksonLogo from '../assets/csFair_sponsor_logos/Erickson_Consulting.png'
import VersoLogo from '../assets/csFair_sponsor_logos/Logo_verso1.png'
import MarvellLogo from '../assets/csFair_sponsor_logos/Marvell.png'
import NDILogo from '../assets/csFair_sponsor_logos/NDI_Logo.png'
import OnLogicLogo from '../assets/csFair_sponsor_logos/OnLogic_Logo_Glassdoor.png'
import KoreLogo from '../assets/csFair_sponsor_logos/kore_power.png'
import ZimmermanFundLogo from '../assets/csFair_sponsor_logos/ZimmermanFundLogo.png'
import Carousel from "../viridis-ui/Carousel";
import {ReactNode} from "react";
export default function Sponsors() {
    const sponsors:SponsorInfo[] = [
        {name: "Erickson", image:EricksonLogo, link:""},
        {name: "Marvell", image: MarvellLogo, link:"https://www.marvell.com/"},
        {name: "On Logic", image:OnLogicLogo, link:"https://www.onlogic.com/"},
        {name: "Kore", image:KoreLogo, link:"https://korepower.com/"},
        {name: "NDI", image: NDILogo, link:"https://www.ndigital.com/"},
        {name: "Zimmerman Fund", image: ZimmermanFundLogo,
            link:"https://www.uvm.edu/news/cems/alumni-fund-computer-science-fair"},
        {name: "Verso", image:VersoLogo, link:"https://verso.w3.uvm.edu/"},
        {name: "CEMS", image:CemsLogo, link:"https://www.uvm.edu/cems"}
    ]
    let items:ReactNode[] = []
    sponsors.map((sponsor) => {
        items.push(
            <SponsorCard info={sponsor} />
        )
    })
    return (
        <Grid
            // outer container (central content)
            container width={'100%'}
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={2}
            mb={3}
            mt={5}
        >
            <Header variant={'h3'} divider color={'secondary.dark'} mt={4}>
                Our Sponsors
            </Header>
            <Typography variant={'h6'} color={'white'} mt={3}>
                The CS Fair would like to thank the following sponsors and supporters
            </Typography>
            <Carousel steps={sponsors.length} items={items} />
        </Grid>
    )
}

interface SponsorInfo {
    name: string
    image: string
    blurb?: string
    link: string
}
function SponsorCard({info}:{info:SponsorInfo}) {

    return(
        <Box sx={{display: "flex", justifyContent: "center", alignItems:'center'}}>
        <Card>
        <CardActionArea href={info.link} target={"_blank"}>
            <Box component={'img'} src={info.image}
                 sx={{
                     objectFit:'contain',
                     maxWidth:'30vw',
                     minHeight:'50vh',
                     maxHeight:'50vh',
                     bgcolor:'white',
                     p:2
                }}
            />
        </CardActionArea>
        </Card>
        </Box>
    )
}
