import Form from '../viridis-ui/OldForm/Form'
import {signup} from "../ApiClient";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {Grid, Typography, Switch, useTheme, styled, } from "@mui/material";
import {PasswordField} from "../viridis-ui/OldForm/FormSpecials";
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';

export default function Signup(props:{handleSignup:Function}) {
    const [type, setType] = useState<'dev'|'org'>('dev')
    const [password, setPassword] = useState<string>('')
    const inputs = [
        {key: "netid", name: "Username"},
        {key: "name", name:"Name"},
        {key:"email", name:"Email"}
    ]

    const success = (res:any) => {
        console.log('success')
        props.handleSignup()
    }

    let specialInputs = [
        {
            state: password,
            component: <PasswordField value={password} update={setPassword} />,
            key: 'password'
        }
    ]
    let dialogText = "W hile some profile information can be changed, your username cannot be."
    return(
        <Form
            header={undefined}
            inputs={inputs}
            apiCall={signup}
            handleSuccess={success}
            specialInputs={specialInputs}
            confDialog={{title: "Create Account?", text:dialogText}}
            noBorder={true}
            buttonContained
        />
    )
}

