/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 hologram_projector_small.glb
Author: Fred Drabble (https://sketchfab.com/FredDrabble)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/hologram-projector-ada909283f1c44b98ca5dfba16ec8cab
Title: Hologram projector
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF("/hologram_projector_small.glb")
  return (
    <group {...props} dispose={null}>
      <group position={[2.135, 8.105, 8.45]} rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[-2.135, 8.45, -8.105]}>
          <mesh geometry={nodes.Box001_Box001_basematerial_0.geometry} material={materials.basematerial} />
          <mesh geometry={nodes.Box001_Box001_heatshield_0.geometry} material={materials.heatshield} />
        </group>
      </group>
      <group position={[2.136, 15.706, 8.451]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Cylinder001_heatshield_0.geometry} material={materials.heatshield} position={[-2.136, 8.451, -15.706]} />
      </group>
      <group position={[2.136, 17.159, 8.409]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Cylinder002_basematerial_0.geometry} material={materials.basematerial} position={[-2.136, 8.409, -17.159]} />
      </group>
      <group position={[-7.001, 12.643, 8.284]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Loft002_pipes_0.geometry} material={materials.pipes} position={[7.001, 8.284, -12.643]} />
      </group>
      <group position={[-10.084, 10.374, 8.291]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box004_bridge_0.geometry} material={materials.bridge} position={[10.084, 8.291, -10.374]} />
      </group>
      <group position={[-6.948, 12.236, 8.314]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box006_blocks_0.geometry} material={materials.blocks} position={[6.948, 8.314, -12.236]} />
      </group>
      <group position={[-4.742, 14.956, 8.291]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box007_bridge_0.geometry} material={materials.bridge} position={[4.742, 8.291, -14.956]} />
      </group>
      <group position={[9.759, 14.951, 8.448]} rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[-9.759, 8.448, -14.951]}>
          <mesh geometry={nodes.card_card_dongles_0.geometry} material={materials.dongles} />
          <mesh geometry={nodes.card_card_blue_dongle_0.geometry} material={materials.blue_dongle} />
        </group>
      </group>
      <group position={[10.362, 13.483, 8.437]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Cylinder003_blocks_0.geometry} material={materials.blocks} position={[-10.362, 8.437, -13.483]} />
      </group>
      <group position={[10.198, 13.262, 8.449]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.plugconnector_blocks_0.geometry} material={materials.blocks} position={[-10.198, 8.449, -13.262]} />
      </group>
      <group position={[8.576, 14.648, 8.449]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box008_backmetal_0.geometry} material={materials.backmetal} position={[-8.576, 8.449, -14.648]} />
      </group>
      <group position={[6.935, 16.528, 9.046]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box009_blue_dongle_0.geometry} material={materials.blue_dongle} position={[-6.935, 9.046, -16.528]} />
      </group>
      <group position={[7.077, 16.711, 8.654]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box010_dongles_0.geometry} material={materials.dongles} position={[-7.077, 8.654, -16.711]} />
      </group>
      <group position={[8.35, 17.994, 9.29]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Cylinder004_dongles_0.geometry} material={materials.dongles} position={[-8.35, 9.29, -17.994]} />
      </group>
      <group position={[2.188, 18.109, 8.235]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.lens_glass_0.geometry} material={materials.glass} position={[-2.188, 8.235, -18.109]} />
      </group>
      <group position={[2.067, 10.374, -3.557]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box016_bridge_0.geometry} material={materials.bridge} position={[-2.067, -3.557, -10.374]} />
      </group>
      <group position={[2.045, 12.236, -0.421]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box017_blocks_0.geometry} material={materials.blocks} position={[-2.045, -0.421, -12.236]} />
      </group>
      <group position={[2.067, 14.956, 1.784]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box018_bridge_0.geometry} material={materials.bridge} position={[-2.067, 1.784, -14.956]} />
      </group>
      <group position={[2.075, 12.643, -0.474]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Loft003_pipes_0.geometry} material={materials.pipes} position={[-2.075, -0.474, -12.643]} />
      </group>
      <group position={[2.06, 10.374, 20.36]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box019_bridge_0.geometry} material={materials.bridge} position={[-2.06, 20.36, -10.374]} />
      </group>
      <group position={[2.082, 12.236, 17.223]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box020_blocks_0.geometry} material={materials.blocks} position={[-2.082, 17.223, -12.236]} />
      </group>
      <group position={[2.06, 14.956, 15.018]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box021_bridge_0.geometry} material={materials.bridge} position={[-2.06, 15.018, -14.956]} />
      </group>
      <group position={[2.053, 12.643, 17.277]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Loft004_pipes_0.geometry} material={materials.pipes} position={[-2.053, 17.277, -12.643]} />
      </group>
      <group position={[14.678, 5.968, 8.446]} rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[-14.678, 8.446, -5.968]}>
          <mesh geometry={nodes.Loft005_Loft005_pipes_0.geometry} material={materials.pipes} />
          <mesh geometry={nodes.Loft005_Loft005_06___Default_0.geometry} material={materials['06___Default']} />
        </group>
      </group>
      <group position={[15.864, 9.951, 8.442]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box022_blocks_0.geometry} material={materials.blocks} position={[-15.864, 8.442, -9.951]} />
      </group>
      <group position={[16.827, 6.564, 8.442]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Box023_blocks_0.geometry} material={materials.blocks} position={[-16.827, 8.442, -6.564]} />
      </group>
      <group position={[2.195, 16.161, 8.248]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Cylinder007_basematerial_0.geometry} material={materials.basematerial} position={[-2.195, 8.248, -16.161]} />
      </group>
      <group position={[2.125, 18.4, 8.419]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Sphere002_glass_0.geometry} material={materials.glass} position={[-2.125, 8.419, -18.4]} />
      </group>
      <mesh geometry={nodes['Cylinder008_13_-_Default_0'].geometry} material={materials['13_-_Default']} position={[2.196, 18.596, 8.278]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.455, 0.455, 1]} />
      <mesh geometry={nodes['Sphere001_Material_#55_0'].geometry} material={materials.Material_55} position={[2.157, 66.812, 8.268]} rotation={[-Math.PI / 2, 0, 0.162]} scale={[1.629, 1.589, 1.545]} />
    </group>
  )
}

useGLTF.preload('/hologram_projector_small.glb')
