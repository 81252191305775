import {useState, SyntheticEvent} from "react";
import { Grid, Card, Tab, Tabs, Snackbar, IconButton, Typography} from "@mui/material";
import CampusBuildingsAbove from "../assets/burlington_waterfront_comp.jpg"
import CloseIcon from "@mui/icons-material/Close"
import Signup from "./Signup";
import Login from "./Login";

export default function AccountPage(props:{startTab:"login"|"signup"}) {
    const [display, setDisplay] = useState<"login"|"signup">(props.startTab)
    const [alertOpen, setAlertOpen] = useState(false)
    const updateDisplay = (e:SyntheticEvent, newValue:"login"|"signup") => {
        setDisplay(newValue)
    }

    const handleSignup = () => {
        setDisplay("login")
        setAlertOpen(true)
    }
    let content;
    if (display === "login") {
        content = <Login />
    }
    else {
        content= <Signup handleSignup={handleSignup}/>
    }


    return (
        <Grid container direction='column'
              spacing={0} item
              alignItems={'center'}
              justifyContent={'center'}
        >
            <Grid item mt={'10vh'}>
                <Tabs value={display} onChange={updateDisplay} centered>
                    <Tab label={<Typography variant={'h6'} color={'grey'}>Login</Typography>} value="login" />
                    <Tab label={<Typography variant={'h6'} color={'grey'}>Signup</Typography>} value="signup" />
                </Tabs>
                <Grid container sx={{backgroundColor: 'rgba(256, 256, 256,.9)', borderRadius: 3}}>
                    {content}
                </Grid>
            </Grid>
            <Snackbar
                open={alertOpen}
                onClose={() => {setAlertOpen(false)}}
                message="Account Created! Please log in!"
                anchorOrigin={{vertical:'bottom', horizontal:'right'}}
                action={
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={() => setAlertOpen(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                  }
            />
        </Grid>

    )

}



