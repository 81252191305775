import {isAxiosError} from "axios";
import {useErrorBoundary} from "react-error-boundary";
import {Button, Grid, Typography} from "@mui/material";
import {Header} from "../viridis-ui";
import {isRouteErrorResponse, useRouteError} from "react-router-dom";

export default function ErrorPage({error}:{error:Error}) {
    const routeError = useRouteError()
    const { resetBoundary } = useErrorBoundary()

    let title = error.name
    let message = error.message
    if (isAxiosError(error) && error.response) {
        title = "Error " + error.response.status + ': ' + error.response.statusText
    }
    else if (isAxiosError(error)) {
        title = "Error 503: Connection Timed Out"
        message = "The server couldn't be accessed, either because your connection " +
            "has expired or because something is preventing your connection"+
            " to us. Please retry your action, if the error persists, we are either " +
            "experiencing an outage, performing maintenance, or you have a "+
            "connectivity issue. "
    }
    else if (isRouteErrorResponse(routeError)) {
        if (routeError.status === 404) {
            title = "Page not found!"
            message = "Sorry, we couldn't find the page you were looking for."
        }
    }

    return (
        <Grid
            container
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Grid container item justifyContent={'center'} alignItems={'center'} spacing={4}>
                <Grid item mt={10} mb={4} xs={8}>
                    <Header variant={'h2'} color={'error.main'} textAlign={'center'}>
                        {title}
                    </Header>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant={'h5'} textAlign={'center'}>
                        {message}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                    <Button variant={'outlined'} color={'primary'} size='large' onClick={resetBoundary}>
                        <Typography variant={'h6'}> Retry</Typography>
                    </Button>
            </Grid>
        </Grid>
    )

}
