import {useAuth} from "../../hooks/useAuth";
import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {PasswordField} from "../../viridis-ui/OldForm/FormSpecials";
import Form from "../../viridis-ui/OldForm/Form";
import {loginAdmin} from "../../ApiClient";
import ManageProjects from "./ManageProjects";
import Stats from "./Stats";
import ManageCats from "./ManageCats";
export default function AdminPage() {
    const {auth} = useAuth()
    //TODO: Integrate admin page into routing flow
    if (auth.userType === "admin") {
        return (
            <><ManageProjects />
                <ManageCats />
            <Stats />
            </>
        )
    }
    else {
        return (
            <AdminLogin />
        )
    }
}



type loginRes = {success:true, netid:string, token:string, userType: UserTypes}

function AdminLogin() {
    const [password, setPassword]  = useState<string>('')
    const {auth, setAuth} = useAuth()

    const success = (res:loginRes) => {
        setAuth({userType:res.userType, token:res.token, netid:res.netid})
        // any pre-nav logic should go here
        console.log('logged in')
    }

    // form component config options
    const inputs = [
        {key:'netid', name:'Netid'},
    ]

    let specialInputs = [
        {
            state : password,
            component: <PasswordField value={password} update={setPassword} />,
            key: 'password'
        }
    ]

    return(
        <Form
            header={undefined}
            inputs={inputs}
            apiCall={loginAdmin}
            handleSuccess={success}
            specialInputs={specialInputs}
            noBorder={true}
            buttonContained
        >

        </Form>

    )
}
