import {Backdrop, CircularProgress, Grid} from "@mui/material";
import {Header} from "../Typography/Header";

export interface LoadingScreenProps {
    show? : boolean,
    color?: string,
}
/*
 * Simple loading screen default using MUI's Backdrop and CircularProgress indicator components.
 * The screen renders at a z-index of 5, putting it on top of any drawers or modals.
 * @param show (optional) value indicating whether the component should render or not. Defaults to true.
 *        Pass in a state value if you are toggling this on/off.
 * @param color (optional) The color of the text and circle. Defaults is the theme secondary color (UVM gold)
 */
export function LoadingScreen({show, color}:LoadingScreenProps) {
    return (
        <Backdrop
            sx={{
                color: ((color)? color : 'secondary.main'),
                zIndex:5
            }}
            open={show ? show : true}
        >
            {/* VISUALS */}
            <Grid container direction='column' justifyContent={'center'} alignItems={'center'} spacing={5}>
                <Grid item>
                    <Header variant='h3' color={'inherit'}>Loading</Header>
                </Grid>
                <Grid item>
                    <CircularProgress color="inherit" />
                </Grid>
            </Grid>
        </Backdrop>
    )
}
