import {useState, useEffect} from "react";
import {Box, Grid, IconButton, Snackbar, Typography} from "@mui/material";
import {Header, LoadingScreen} from "../../viridis-ui";
import {getStudentProjects} from "../../ApiClient";
import {useErrorBoundary} from 'react-error-boundary'
import OldInfoDisplay from "../../viridis-ui/OldInfoDisplay/OldInfoDisplay";
import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
export default function MyProjs() {
    const [projs, setProjs] = useState<Project[] | null>(null)
    const {showBoundary} = useErrorBoundary()
    const {auth} = useAuth()
    useEffect(() => {
        getProjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getProjects = () => {
        getStudentProjects(auth.netid)
            .then((res)=> {
                //@ts-ignore
                setProjs(res)
            })
            .catch((e) => {showBoundary(e)})
    }

    if (Array.isArray(projs) && projs.length===0) {
        return <Typography variant={'h4'}>
            You haven't registered a project yet. Select Submit Project and fill out the form to do so.
        </Typography>
    }
    else if (Array.isArray(projs)) {
        let display = <Grid item><MakePD projects={projs} /></Grid>
        return (
        <Grid
            container direction={'column'}
            alignItems={'center'} justifyContent={'flex-start'}
        >
            <Grid
                container direction={'column'}
                spacing={2} sx={{mt:3}} pr={1.5}
                xs={10}
                alignItems={'center'}
                justifyContent={'flex-start'}
            >
                {display}
            </Grid>
        </Grid>
        )
    }
    else {
        return <LoadingScreen />
    }
}

function MakePD(props: { projects:Project[] }) {
    let toDisplay = []
    for (let p of props.projects) {
        toDisplay.push(<Grid item><ProjectDisplay project={p}/></Grid>)
    }
    return <Grid item>{toDisplay}</Grid>
}

function ProjectDisplay(props:{project:Project}) {
    let project = props.project
    let info = [
        {label: "ID", info: project.id},
        {label: "Title", info: project.title},
        {label: "Description", info: project.description},
        {label: "Category", info: project.category},
        {label: "Group Size", info: project.groupSize},
        {label: "Group Info", info: project.students},
        {label: "Approval", info: project.approved},
        {label: "Booth", info: project.booth},
        {label: "Timeslot", info: project.timeslot},
    ]

    return (
        <Box sx={{mb: 2}}>
            <OldInfoDisplay title={project.title} info={info}>
            </OldInfoDisplay>
        </Box>
    )
}
