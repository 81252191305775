import {useState, useEffect} from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration"
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {Grid, Typography} from "@mui/material";
import Grow from '@mui/material/Grow'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
dayjs.extend(duration)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('America/New_York')

interface timeDict {
    hours: string,
    minutes: string,
    seconds: string,
    days: number |string
}
export default function CountdownTimer({eventDate}:{eventDate:string}) {
    const [currentTime, setCurrentTime] = useState(dayjs());
    const [timeRemaining, setTimeRemaining] = useState<timeDict | 0>(calculateTimeRemaining());
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    function calculateTimeRemaining() {
        const now = dayjs();
        const start = dayjs(eventDate,"MM-DD-YYYY hh:mm:ss A" )
        const dur = dayjs.duration(start.diff(now));
        if (dur.asSeconds() <= 0) {return 0}
        return {
            days: Math.floor(dur.as('days')),
            hours: String(dur.hours()).padStart(2, '0'),
            minutes: String(dur.minutes()).padStart(2, '0'),
            seconds: String(dur.seconds()).padStart(2, '0')
        }
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(dayjs());
            const remaining = calculateTimeRemaining()
            if (remaining === 0) {
                clearInterval(timer)
            } else {
                setTimeRemaining(remaining)
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);


    if (timeRemaining != 0) {
        if (matches) {
            return (
                <Grow in={true} style={{transitionDelay: '1300ms'}}>
                    <Grid
                        container direction={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={1}
                    >
                        <Grid
                            container item
                            direction={'row'}
                            spacing={0}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <Grid item xs={2.5}>
                                <Typography
                                    variant={'h1'}
                                    color={'secondary.main'}
                                    fontFamily={'oswald'}
                                    letterSpacing={'.5rem'}
                                    textAlign={'center'}
                                >
                                    {timeRemaining.days}
                                </Typography>
                            </Grid>
                            <Grid item xs={2.5}>
                                <Typography
                                    variant={'h1'}
                                    color={'secondary.main'}
                                    fontFamily={'oswald'}
                                    letterSpacing={'.5rem'}
                                    textAlign={'center'}
                                >
                                    {timeRemaining.hours}
                                </Typography>
                            </Grid>
                            <Grid item xs={2.5}>
                                <Typography
                                    variant={'h1'}
                                    color={'secondary.main'}
                                    fontFamily={'oswald'}
                                    letterSpacing={'.5rem'}
                                    textAlign={'center'}
                                >
                                    {timeRemaining.minutes}
                                </Typography>
                            </Grid>
                            <Grid item xs={2.5}>
                                <Typography
                                    variant={'h1'}
                                    color={'secondary.main'}
                                    fontFamily={'oswald'}
                                    letterSpacing={'.5rem'}
                                    textAlign={'center'}
                                >
                                    {timeRemaining.seconds}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container item
                            direction={'row'}
                            spacing={0}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >

                            <Grid item xs={2.5}>
                                <Typography
                                    variant={'h5'}
                                    color={'secondary.main'}
                                    textAlign={'center'}
                                    fontWeight={'bold'}
                                    fontStyle={'oblique'}
                                >
                                    Days
                                </Typography>
                            </Grid>
                            <Grid item xs={2.5}>
                                <Typography
                                    variant={'h5'}
                                    color={'secondary.main'}
                                    textAlign={'center'}
                                    fontWeight={'bold'}
                                    fontStyle={'oblique'}
                                >
                                    Hours
                                </Typography>
                            </Grid>
                            <Grid item xs={2.5}>
                                <Typography
                                    variant={'h5'}
                                    color={'secondary.main'}
                                    textAlign={'center'}
                                    fontWeight={'bold'}
                                    fontStyle={'oblique'}
                                >
                                    Minutes
                                </Typography>
                            </Grid>
                            <Grid item xs={2.5}>
                                <Typography
                                    variant={'h5'}
                                    color={'secondary.main'}
                                    textAlign={'center'}
                                    fontWeight={'bold'}
                                    fontStyle={'oblique'}
                                >
                                    Seconds
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grow>
            )
        }
        else {
            return(
                <Grow in={true} style={{transitionDelay: '1000ms'}}>
                    <Grid
                        container direction={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={1}
                    >
                        <Grid item>
                            <Typography
                                variant={'h1'}
                                color={'secondary.main'}
                                fontFamily={'oswald'}
                                letterSpacing={'.5rem'}
                                textAlign={'center'}
                                mb={2}
                            >
                                {timeRemaining.days}
                            </Typography>
                            <Typography
                                    variant={'h5'}
                                    color={'secondary.main'}
                                    textAlign={'center'}
                                    fontWeight={'bold'}
                                    fontStyle={'oblique'}
                                >
                                    Days
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant={'h1'}
                                color={'secondary.main'}
                                fontFamily={'oswald'}
                                letterSpacing={'.5rem'}
                                textAlign={'center'}
                                mb={2}
                            >
                                {timeRemaining.hours}
                            </Typography>
                            <Typography
                                    variant={'h5'}
                                    color={'secondary.main'}
                                    textAlign={'center'}
                                    fontWeight={'bold'}
                                    fontStyle={'oblique'}
                                >
                                    Hours
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant={'h1'}
                                color={'secondary.main'}
                                fontFamily={'oswald'}
                                letterSpacing={'.5rem'}
                                textAlign={'center'}
                                mb={2}
                            >
                                {timeRemaining.minutes}
                            </Typography>
                            <Typography
                                    variant={'h5'}
                                    color={'secondary.main'}
                                    textAlign={'center'}
                                    fontWeight={'bold'}
                                    fontStyle={'oblique'}
                                >
                                    Minutes
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant={'h1'}
                                color={'secondary.main'}
                                fontFamily={'oswald'}
                                letterSpacing={'.5rem'}
                                textAlign={'center'}
                                mb={2}
                            >
                                {timeRemaining.seconds}
                            </Typography>
                            <Typography
                                    variant={'h5'}
                                    color={'secondary.main'}
                                    textAlign={'center'}
                                    fontWeight={'bold'}
                                    fontStyle={'oblique'}
                                >
                                    Seconds
                            </Typography>
                        </Grid>
                    </Grid>
                </Grow>
            )
        }
    }
    else {
        return (
            <Grow in={true} style={{transitionDelay: '1000ms'}}>
                <Grid
                    container direction={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    spacing={1}
                >
                    <Typography
                        variant={'h1'}
                        color={'secondary.main'}
                        fontFamily={'oswald'}
                        letterSpacing={'.5rem'}
                        textAlign={'center'}
                        mb={2}
                    >Congrats Winners!</Typography>
                </Grid>
            </Grow>
        )
    }
}

