import {createBrowserRouter, RouterProvider, Outlet, Navigate, useLocation} from "react-router-dom";
import {Box, CssBaseline, ThemeProvider} from '@mui/material'
import {theme} from './viridis-ui'
import {ErrorBoundary, useErrorBoundary} from 'react-error-boundary'
import Navbar from "./global/Navbar";
import Home, {HomeView} from "./pages/Home/Home";
import Sponsors from "./pages/Sponsors";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Footer from "./global/Footer";
import {QueryCache, QueryClient, QueryClientProvider} from "@tanstack/react-query";
import ErrorPage from "./global/ErrorPage";
import {PropsWithChildren, useEffect, useState} from "react";
import AccountPage from "./pages/AccountPage";
import Dashboard from "./pages/User/Dashboard";
import {AuthProvider} from "./hooks/useAuth";
import AdminPage from "./pages/Admin/AdminPage"

interface PageRecord {page:string, time:number}
function Analytics() {

    // Analytics initialization and logic
    const location = useLocation()
    const [timeOnSite, setTimeOnSite] = useState(0)
    const [timeOnPage, setTimeOnPage] = useState(0)
    const [pageLog, setPageLog] = useState<Array<PageRecord>>([])
    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTimeOnSite((prev) => prev + 1)
            setTimeOnPage((prev) => prev + 1)
        }, 1000)
        return () => clearInterval(timerInterval)
    }, [])

    useEffect(() => {
        const handlePageClose = () => {
            pageLog.push({page:location.pathname, time:timeOnPage})
            const metrics = {
                time: timeOnSite,
                pageLog: pageLog
            }
            // api call
            console.log(metrics)
        }

        window.addEventListener('pagehide', handlePageClose)
        return () => {window.removeEventListener('pagehide', handlePageClose)}
    }, [])

    useEffect(() => {
        setPageLog((prev) => [...prev, {page:location.pathname, time: timeOnPage}])
        setTimeOnPage(0)
        console.log(pageLog)
    }, [location.pathname])

    return null
}
function QueryProvider({children}: PropsWithChildren) {
    const {showBoundary} = useErrorBoundary()
    const queryClient = new QueryClient({
        queryCache: new QueryCache({
            onError: (error) => {
                showBoundary(error)
            }
        }),
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false
            }
        }
    })
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}
function Root({error}:{error?:boolean}) {

    return (
        <Box
            sx={{
                display:'flex',
                flexGrow: 1,
                flexDirection:'column',
                minHeight:'100vh'
            }}
        >
            <Analytics />
            <ErrorBoundary FallbackComponent={ErrorPage} onError={(e) => console.error(e)}>
                <QueryProvider>
                    {error? <ErrorPage error={new Error()} /> : <Home />}
                </QueryProvider>
            </ErrorBoundary>
            <Footer />
        </Box>
    )
}
function App() {
    const router = createBrowserRouter([{
        path: '/',
        element: <Root/>,
        errorElement: <Root error/>,
        children: [
            {index: true, element: <Navigate to={'home'} />},
            {path: 'home', element: <HomeView />},
            {path: 'about', element: <About />},
            {path: 'projects', element: <Projects/>},
            {path: 'sponsors', element: <Sponsors />},
            {path: "login", element: <AccountPage startTab={'login'} />},
            {path: "signup", element: <AccountPage startTab={'signup'} />},
            {path: "student/:netid/", element:<Dashboard />},
            {path: "admin", element:<AdminPage />}
        ]
    },])
    return (
        <ThemeProvider theme={theme}>
        <CssBaseline />
            <AuthProvider>
            <RouterProvider router={router} />
            </AuthProvider>
        </ThemeProvider>
    )
}

export default App;
