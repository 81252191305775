import {Grid, Tab, Tabs, Typography} from '@mui/material'
import ViewCategories from "./ViewCategories";
import HeroBanner from "./HeroBanner";
import Rules from "./Rules";
import {useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import Winners from "../Winners";
import GlobeHolo from "./GlobeHolo";

interface AuthInfo {
    netid: string,
    token: string,
    userType: "student" | "admin"
}
export default function Home() {
    const [openMenu, setOpenMenu] = useState(false)
    const [page, setPage] = useState<string>("/home")
    const {auth} = useAuth()
    const nav = useNavigate()
    let ldKey = (auth?.netid != '' && auth?.userType != undefined)
        ? {label: "Dashboard", link:("/"+auth.userType+"/"+auth.netid)}
        : {label: "Login", link: "/login"}
    const handleChange = (val:string) => {
        setPage(val)
        nav(val)
    }

    return (
        <Grid
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={0}
            // removes default margin from navbar
            // mt={-4}
            mb={4}
        >
            <Grid item height={'100vh'} width={'100%'}>
                <HeroBanner openMenu={openMenu} setMenuOpen={setOpenMenu}/>
            </Grid>
            <Grid item sm={12} mt={4.5}>
                <Tabs value={page} onChange={(e, val) => {handleChange(val)}} centered>
                    <Tab
                        label={<Typography color="white" variant={'h5'}>Home</Typography>}
                        value={"/home"}
                        sx={{textTransform:'none'}}
                    />
                    <Tab label={<Typography color="white"  variant={'h5'}>Projects</Typography>}
                         value={"/projects?category=all"}
                         sx={{textTransform:'none'}}
                    />
                    <Tab label={<Typography color="white"  variant={'h5'}>About</Typography>}
                         value={"/about"}
                         sx={{textTransform:'none'}}
                    />
                    <Tab label={<Typography color="white"  variant={'h5'}>Sponsors</Typography>}
                         value={'/sponsors'}
                         sx={{textTransform:'none'}}
                    />
                    <Tab label={<Typography color="white"  variant={'h5'}>{ldKey.label}</Typography>}
                         value={ldKey.link}
                         sx={{textTransform:'none'}}
                    />
                  </Tabs>
            </Grid>
            <Outlet />
        </Grid>
    )
}

export function HomeView() {
    return (
    <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'}
          sx={{
                backgroundColor: 'primary.dark',
            }}
    >
        <Grid container direction={'row'}>
            <Grid item md={6} xs={0} sx={{display: {xs:'none', md:'flex'}}} >
                    <GlobeHolo/>
            </Grid>
            <Grid item xs={6}>
                <Winners />
            </Grid>
        </Grid>
        <Grid container xs={8.5} mt={8} mb={4}>
                <Rules/>
        </Grid>
    </Grid>
    )
}
