import { login} from "../ApiClient";
import { useNavigate} from "react-router-dom";
import {useAuth} from '../hooks/useAuth'
import Form from '../viridis-ui/OldForm/Form'
import {useState, useEffect} from 'react'
import {PasswordField} from "../viridis-ui/OldForm/FormSpecials";
import {Button, Typography} from '@mui/material'
import { useSearchParams } from "react-router-dom";

type loginRes = {success:true, netid:string, token:string, userType: UserTypes}
export default function Login() {
    const [password, setPassword]  = useState<string>('')
    const nav = useNavigate()
    const {auth, setAuth} = useAuth()
    const [searchParams] = useSearchParams()
    // this triggers navigation when the internal auth context is updated, to prevent async issues
    // without the useEffect call, the navigation occurs before the state update and it navs to undefined
    useEffect(()=>{
        if (auth.userType !== undefined && auth.netid !== ''){
            let url;
            if (searchParams.get('project')) {
                url = '/' + auth.userType + '/' + auth.netid + '/apply/'+ searchParams.get('project')
            }
            else if (searchParams.get('sublink')) {
                url = '/' + auth.userType + '/' + auth.netid + searchParams.get('sublink')
            }
            else {
                url = '/' + auth.userType + '/' + auth.netid
            }
            nav(url)
        }
    }, [auth])

    const success = (res:loginRes) => {
        setAuth({userType:res.userType, token:res.token, netid:res.netid})
        // any pre-nav logic should go here
        console.log('logged in')
    }

    // form component config options
    const inputs = [
        {key:'netid', name:'Username'},
    ]

    let specialInputs = [
        {
            state : password,
            component: <PasswordField value={password} update={setPassword} />,
            key: 'password'
        }
    ]

    return(
        <Form
            header={undefined}
            inputs={inputs}
            apiCall={login}
            handleSuccess={success}
            specialInputs={specialInputs}
            noBorder={true}
            buttonContained
        >
            <Button size='small' sx={{mt:3, textTransform:'none'}} onClick={()=>{nav('/trigger_reset')}} >
                <Typography fontWeight={550} variant="h6"><u>Forgot Password?</u></Typography>
            </Button>
        </Form>

    )
}
