import {MobileStepper,Grid, IconButton} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useState, useEffect, ReactNode} from "react";
export default function Carousel(props:{steps:number, items:ReactNode[]}) {
    const [index, setIndex] = useState(0)
    useEffect(() => {
        // autoplay carousel
        const interval = setInterval(() => {
            stepForward()
        }, 7000)

        return () => clearInterval(interval)
    }, [index])
    const stepForward = () => {
        if (index+1 >= props.steps) {
            setIndex(0)
        }
        else {
            setIndex(index+1)
        }
    }

    const stepBack = () => {
        if (index-1 < 0) {
            setIndex(props.steps-1)
        }
        else {
            setIndex(index-1)
        }
    }
    return(
        <Grid container direction={'column'} alignItems={'center'} justifyContent={'flex-start'}>
            <MobileStepper
                variant='dots'
                backButton={null}
                nextButton={null}
                steps={props.steps}
                position={'static'}
                activeStep={index}
                sx={{mt:'5vh'}}
            />
            <Grid
                mt={'1.5vh'}
                container direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                spacing={0}
            >
                <Grid item xs={1}>
                    <IconButton onClick={() => {stepBack()}}>
                        <ArrowBackIosIcon sx={{color:'black'}}/>
                    </IconButton>
                </Grid>
                <Grid item xs={10}>
                    {props.items[index]}
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={() => {stepForward()}}>
                        <ArrowForwardIosIcon sx={{color:'black'}}/>
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    )
}
