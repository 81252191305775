import { Canvas, useFrame } from '@react-three/fiber';
import { Suspense, useRef } from 'react';
import { OrbitControls, Environment } from '@react-three/drei';
import { Model } from '../../assets/Hologram_projector_small';

export default function GlobeHolo() {
    return (
        <Canvas
            camera={{ position: [10, 110, 50], fov: 75 }}
            style={{
                backgroundColor: '#01533F',
                width: '100%',
                height: '90vh',
            }}
        >
            <ambientLight intensity={1.35} />
            <directionalLight intensity={0.4} />
            <Suspense fallback={null}>
                <Model position={[0,0,0]} />
            </Suspense>
            <OrbitControls
                autoRotate={true}
                autoRotateSpeed={2.5}
                target={[0, 70, 0]}
                enableZoom={false}   //Disable zooming
                enablePan={false}  // Disable panning
                maxPolarAngle={Math.PI / 2}  // Limit vertical rotation (set to exactly horizontal)
                minPolarAngle={Math.PI / 2}
            />
        </Canvas>
    );
}
