import {Header, LoadingScreen} from "../viridis-ui";
import {useEffect, useState} from "react";
import {useErrorBoundary} from "react-error-boundary";
import {useAuth} from "../hooks/useAuth";
import {getApprovedProjects, getStudentProjects} from "../ApiClient";
import {Box, Button, Grid, Link, MenuItem, Select, TextField, Typography} from "@mui/material";
import OldInfoDisplay from "../viridis-ui/OldInfoDisplay/OldInfoDisplay";
import {useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import useSearchNavigate from "../hooks/useSearchNavigate";
import {getNames} from "../utils/groupInfo";

export default function Projects() {
    const [searchParams] = useSearchParams()
    const [projs, setProjs] = useState<Project[]| null>(null)
    const {showBoundary} = useErrorBoundary()
    let searchedCat = searchParams.get('category') as Categories | null | "all"
    if (!searchedCat) {
        searchedCat = "all"
    }
    const [selectedCategory,setSC] = useState<Categories | "all">(searchedCat)
    const nav = useNavigate()
    const searchNav = useSearchNavigate()
    useEffect(() => {
        getProjects()
        if (selectedCategory == null) {
            nav('/projects')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (selectedCategory === "all") {
            nav('/projects')
        }
        else {
            searchNav('/projects',  {category:selectedCategory})
        }
    }, [selectedCategory])
    const getProjects = () => {
        getApprovedProjects()
            .then((res)=> {
                //@ts-ignore
                setProjs(res)
            })
            .catch((e) => {showBoundary(e)})
    }

    if (Array.isArray(projs) && projs.length===0) {
        return <Typography variant={'h4'} mt={6} color={'secondary.dark'}>
            Nobody has registered a project, log in and submit one to be the first to show up here!
        </Typography>
    }
    else if (Array.isArray(projs)) {
        let filtered = projs
        if (searchParams.get('category')
            && searchParams.get('category') !== null
            && searchParams.get('category') !== "all"){
            filtered = []
            for (let p of projs) {
                if (p.category === searchParams.get('category')){
                    filtered.push(p)
                }
            }
        }
        let categoryList = [
            "Advanced App" , "Machine Learning" ,
            "Advanced Programming","Research Projects" , "Intermediate Programming" ,
            "Intermediate Web Design", "Entry Level Programming" , "Entry Level Website"
        ]
        let display = <Grid item><MakePD projects={filtered} /></Grid>
        return (
        <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'}>
        <Grid
            container direction={'column'} xs={10}
            alignItems={'center'} justifyContent={'flex-start'}
        >
            <Grid item mt={4}>
                <Header color={'secondary.dark'} variant={'h3'} >
                    {(searchParams.get("category"))
                        ? searchParams.get('category')
                        : "All Projects"
                    }
                </Header>
            </Grid>
            <Grid
                container item xs={4}
                direction={'row'}
                alignItems={'center'}
                justifyContent={'flex-start'}
                mt={.5}
            >
                <Select
                    name={'Category'}
                    value={selectedCategory}
                    //@ts-ignore
                    onChange={(e) => {setSC(e.target.value)}}
                >
                    <MenuItem
                        value={"all"}
                        sx={{
                              "&.Mui-selected": {backgroundColor: "primary.dark"},
                              backgroundColor: "primary.main",
                              ":hover": {backgroundColor: "primary.dark"}
                          }}
                    >
                        <Typography color={'white'} variant={'subtitle1'}>All Projects</Typography>
                    </MenuItem>
                    {categoryList.map((value) => (
                        <MenuItem value={value}
                          sx={{
                              "&.Mui-selected": {backgroundColor: "primary.dark"},
                              backgroundColor: "primary.main",
                              ":hover": {backgroundColor: "primary.dark"}
                          }}
                        >
                        <Typography color={'white'} variant={'subtitle1'}>{value}</Typography>
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            {(filtered.length === 0)
                ? (
                    <Grid item >
                    <Typography variant={'h4'} mt={6} color={'secondary.dark'}>
                        Nobody has registered a project in this category now,
                        log in and submit one to be the first!
                    </Typography>
                    </Grid>
                )
                : (
                    <Grid container direction={'column'} spacing={2} sx={{mt:3}} pr={1.5}>
                        {display}
                    </Grid>
                )
            }

        </Grid>
        </Grid>
        )
    }
    else {
        return <LoadingScreen />
    }
}

function MakePD(props: { projects:Project[] }) {
    let toDisplay = []
    for (let p of props.projects) {
        toDisplay.push(<Grid item><ProjectDisplay project={p}/></Grid>)
    }
    return <Grid item>{toDisplay}</Grid>
}

function ProjectDisplay(props:{project:Project}) {
    let project = props.project
    let studentsList = project.students.split(',')
    let displayNames:string[] = []
    for (let i of studentsList) {
        if (!i.includes('@')) {displayNames.push(i)}
    }

    let info = [
        {label: "ID", info:project.id},
        {label: "Description", info: project.description},
        {label: "Category", info: project.category},
        {label: "Group Size", info: project.groupSize},
        {
            label: "Group Members",
            info: (displayNames.length > 0)
                    ? displayNames.join()
                    : project.students
        },
        {label: "Booth", info: project.booth},
        {label: "Timeslot", info: project.timeslot},
    ]

    return (
        <Box sx={{mb: 2}}>
            <OldInfoDisplay title={project.title} info={info}>
            </OldInfoDisplay>
        </Box>
    )
}

