import {Box, Button, Grid, IconButton, MenuItem, Snackbar, Tab, Tabs, TextField, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import {Header, LoadingScreen} from "../../viridis-ui";
import {SyntheticEvent, useEffect, useState} from "react";
import {useErrorBoundary} from "react-error-boundary";
import {useNavigate} from "react-router-dom";
import OldInfoDisplay from "../../viridis-ui/OldInfoDisplay/OldInfoDisplay";
import CloseIcon from "@mui/icons-material/Close";
import ThumbUpIcon from "@mui/icons-material/ThumbUp"
import ThumbDownIcon from "@mui/icons-material/ThumbDown"
import {
    getAllProjects,
    setProjectApproval,
    assignBooth,
    changeCategory,
    changeTS
} from "../../ApiClient";

type filters = "all" | "pending" | "approved" | "denied"
export default function ManageProjects() {
    const [projects, setProjects] = useState<Project[]>()
    const {showBoundary} = useErrorBoundary()
    const [select, setSelect] = useState<filters>("pending")
    useEffect(() => {
        getProjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getProjects = () => {
        getAllProjects()
            .then((res)=> {
                //@ts-ignore
                setProjects(res)
            })
            .catch((e) => {showBoundary(e)})
    }
    const handleSelect = (event:SyntheticEvent, val:filters) => {
        setSelect(val)
    }
if (projects){
        // tob->filter mapping
        let filtered:Project[] = []
        let display;
        switch (select) {
            case "all":
                filtered = projects
                break;
            case "pending":
                filtered = []
                for (let p of projects) {
                    if (p.approved === "pending") {
                        filtered.push(p)
                    }
                }
                break;
            case "denied":
                filtered = []
                for (let p of projects) {
                    if (p.approved === "denied"){
                        filtered.push(p)
                    }
                }
                break;
            case "approved":
                filtered = []
                for (let p of projects) {
                    if (p.approved === "approved") {
                        filtered.push(p)
                    }
                }
                break;
        }
        display = <Grid item><MakePD projects={filtered} reload={getProjects} /></Grid>

        // @ts-ignore
    return (
            <Box sx={{flexGrow:1}}>
                <Grid container direction={'column'}
                  spacing={2} justifyContent={'center'}
                  alignItems={'center'} mb={3} ml={1} mt={3}
                >
                    <Grid container
                          direction={'column'}
                          spacing={0}
                          alignItems={'center'}
                          justifyContent={'center'}
                          mt={2} mb={1}
                    >
                        <Grid item>
                            <Header variant={'h3'} color={'secondary.dark'} divider>
                                Project Management Menu
                            </Header>
                        </Grid>
                        <Grid item xs={6} width={'50%'}>
                            <Divider flexItem sx={{borderBottom:2.5, borderRadius:1, color:'primary.dark', mt:1.5, mb:1}}/>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Tabs
                            value={select}
                            onChange={handleSelect}
                            textColor="primary"
                            indicatorColor="primary"
                            aria-label="secondary tabs example"
                        >
                            <Tab value="pending"
                                label={<Typography variant={'h6'}>Pending</Typography>} />
                            <Tab value="approved"
                                label={<Typography variant={'h6'}>Approved</Typography>} />
                            <Tab value="declined"
                                label={<Typography variant={'h6'}>Declined</Typography>} />
                            <Tab value="all"
                                label={<Typography variant={'h6'}>All</Typography>} />
                        </Tabs>
                    </Grid>
                    <Grid container direction={'column'} spacing={2} sx={{mt:3}} pr={1.5} xs={10}>
                        {display}
                    </Grid>
                </Grid>

            </Box>
        )
    }
    else {
        return <LoadingScreen/>
    }
    // mutable display state
        // horizontal menu with filters: Pending Approval, Open, Closed, All
        // clicking a tab filters by that condition and passes those projects to the MakePD component

}


interface pdProps {
    projects: Project[]
    reload: ()=>void
}
function MakePD(props:pdProps) {
    if (props.projects.length !== 0) {
        let toDisplay = []
        for (let p of props.projects) {
            toDisplay.push(<Grid item><ProjectDisplay project={p} reload={props.reload} /></Grid>)
        }
        return <Grid item>{toDisplay}</Grid>
    }
    else {
        return <Typography variant={'h5'}></Typography>
    }
}


function ProjectDisplay(props:{project:Project, reload:()=>void;}) {
    const nav = useNavigate()
    const [alertOpen, setAlertOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [category, setCategory] = useState(props.project.category)
    const [booth, setBooth] = useState<string>("Not Assigned")
    const [timeslot, setTimeslot] = useState<string>(props.project.students)
    const {showBoundary} = useErrorBoundary()

    let project = props.project
    let p = project

    function ApproveSelect() {
        // @ts-ignore
        return (
            <Grid
                    container item spacing={1}
                    direction={'row'} alignItems={'center'}
                    justifyContent={'space-evenly'}
                >
                <Grid item>
                    <Button sx={{minWidth:230}}
                            variant='contained' size='medium'
                            color={'primary'}
                            onClick={() => handleApprove()}
                            startIcon={<ThumbUpIcon />}
                    >
                        <Typography >
                            Approve
                        </Typography>
                    </Button>
                </Grid>
                <Grid item>
                <Button sx={{minWidth:230}}
                        variant='contained' size='medium'
                        color={'error'}
                        onClick={() => handleReject()}
                        startIcon={<ThumbDownIcon />    }
                >
                    <Typography>
                        Reject
                    </Typography>
                </Button>
                </Grid>
            </Grid>
        )
    }

    const handleApprove = async () => {
        //@ts-ignore
        let res = await setProjectApproval({projectid: project.id, approval: "approved"})
        //@ts-ignore
        if (res['success']) {
            props.reload()
        } else {
            console.log(res)
        }
        // TODO: End of flow
    }
    const handleReject = async () => {
        //@ts-ignore
        let res = await setProjectApproval({projectid: project.id, approval: "denied"})
        //@ts-ignore
        if (res['success']) {
            props.reload()
        } else {
            console.log(res)
        }
    }

    const handleAssignBooth = async () => {
            let res = await assignBooth(booth, project.id)
            if (res['success']){props.reload()}
            else {
                console.log(res)
            }
    }
    const handleChangeCat = async() => {
        let res = await changeCategory(category, project.id)
        if (res['success']){props.reload()}
        else{
            console.log(res)
        }
    }

    const handleChangeTS = async() => {
        let res = await changeTS(timeslot, project.id)
        if (res['success']){props.reload()}
        else{
            console.log(res)
        }
    }

    let info = [
        {label: "ID", info:project.id},
        {label: "Title", info:project.title},
        {label: "Description", info:project.description},
        {label: "Category", info: project.category},
        {label: "Group Size", info:project.groupSize},
        {label: "Group Info", info:project.students},
        {label: "Approval", info:project.approved},
        {label: "Booth", info:project.booth},
        {label: "Timeslot", info:project.timeslot},
    ]

    return(
        <Box sx={{mb:2}}>
        <OldInfoDisplay title={project.title} info={info}>
            <Grid container direction='column' spacing={2} mt={2}>
                <Grid
                    container item spacing={1}
                    direction={'row'} alignItems={'center'}
                    justifyContent={'space-evenly'}
                >
                </Grid>
                <Grid
                    container item spacing={0}
                    direction={'row'} alignItems={'center'}
                    justifyContent={'space-evenly'}
                >
                    <Grid item>
                    <ApproveSelect />
                    </Grid>
                </Grid>
                <Grid
                    container item spacing={0}
                    direction={'row'} alignItems={'center'}
                    justifyContent={'space-evenly'}
                >
                    <Grid item>
                        <TextField
                            sx={{width:300}}
                            value={booth}
                            //@ts-ignore
                            onChange={(e) => setBooth(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Button size={'large'} onClick={() => handleAssignBooth()}>
                            <Typography variant={'h6'}>Assign Booth Num</Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    container item spacing={0}
                    direction={'row'} alignItems={'center'}
                    justifyContent={'space-evenly'}
                >
                    <Grid item xs={8} minWidth={'20vw'}>
                    <TextField
                        select name={'category'}
                        label={'Category'} fullWidth
                        value={category}

                        onChange={(e) => {
                            //@ts-ignore
                            setCategory(e.target.value)
                        }}
                    >
                        <MenuItem value={"Advanced Programming"}>Advanced Programming</MenuItem>
                        <MenuItem value={"Machine Learning"}>Machine Learning</MenuItem>
                        <MenuItem value={"Advanced App"}>Advanced App</MenuItem>
                        <MenuItem value={"Research Projects"}>Research Projects</MenuItem>
                        <MenuItem value={"Intermediate Programming"}>Intermediate Programming</MenuItem>
                        <MenuItem value={"Intermediate Web Design"}>Intermediate Web Design</MenuItem>
                        <MenuItem value={"Entry Level Programming"}>Entry Level Programming</MenuItem>
                        <MenuItem value={"Entry Level Website"}>Entry Level Website</MenuItem>
                    </TextField>
                </Grid>
                    <Grid item>
                        <Button size={'large'} onClick={() => handleChangeCat()}>
                            <Typography variant={'h6'}>Change Category</Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    container item spacing={0}
                    direction={'row'} alignItems={'center'}
                    justifyContent={'space-evenly'}
                >
                    <Grid item xs={8} minWidth={'20vw'}>
                        <TextField
                            select name={'timeslot'}
                            label={'Time Slot'} fullWidth
                            value={timeslot}
                            onChange={(e) => {
                                //@ts-ignore
                                let tslabel = e.target.value
                                //@ts-ignore
                                if (tslabel === 1) {
                                    setTimeslot("1:10-2:00")
                                }
                                //@ts-ignore
                                else if (tslabel === 2) {
                                    setTimeslot("2:20-3:10")
                                }
                                //@ts-ignore
                                else if (tslabel === 3) {
                                    setTimeslot("3:30-4:20")
                                }
                            }}
                        >
                            <MenuItem value={1}>1:10 - 2:00</MenuItem>
                            <MenuItem value={2}>2:20 - 3:10</MenuItem>
                            <MenuItem value={3}>3:30 - 4:20</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item>
                        <Button size={'large'} onClick={() => handleChangeTS()}>
                            <Typography variant={'h6'}>change timeslot</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
                open={alertOpen}
                onClose={() => {setAlertOpen(false)}}
                message={message}
                anchorOrigin={{vertical:'bottom', horizontal:'right'}}
                action={<IconButton
                            size="small"
                            color="inherit"
                            onClick={() => setAlertOpen(false)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                  }
            />
        </OldInfoDisplay>
        </Box>
    )
}




