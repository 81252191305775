import Typography, {TypographyProps} from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

/*
 * Bolds the given text without breaking for a new line.
 * Accepts all props of the MUI Typography component
 */
export const Bolded = styled(Typography)<TypographyProps>(() => ({
    display: "inline",
    variant: 'inherit',
    fontWeight: "bold"
}))


