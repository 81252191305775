import {useState, useEffect} from 'react'
import {getCats, delCat, addCat, getWinningProjs} from "../../ApiClient";
import {useErrorBoundary} from "react-error-boundary";
import {Button, Grid, TextField, Typography} from '@mui/material'
interface Category {
    id: number
    name: string
}
export default function ManageCats() {
    const [cats, setCats] = useState<Array<Category>>([])
    const {showBoundary} = useErrorBoundary()
    const [newCat, setNC] = useState('')
    const [delCatID, setDelID] = useState(-1)
    useEffect(() => {
        getData()
    }, [])

    const handleNC = () => {
        addCat(newCat).then((res) => {
            setNC("")
            getData()
        })
            .catch((e) => {showBoundary(e)})

    }

    const handleDelCat = () => {
        delCat(delCatID).then((res) => {
            setDelID(-1)
            getData()
        })
            .catch((e) => {showBoundary(e)})
    }
    const getData = () => {
        getCats().then((res)=> {
            //@ts-ignore
            setCats(res)
        })
        .catch((e) => {showBoundary(e)})
    }
    let cDis = []
    for (let c of cats) {
        cDis.push(
            <Grid item><Typography variant={'h6'}>ID: {c.id}, Name: {c.name}</Typography></Grid>
        )
    }
    return (
        <Grid container direction={'column'}
              alignItems={'center'}
            justifyContent={'center'}
              sx={{backgroundColor:'white'}} pl={5} py={4}
        >
            <Grid item><Typography variant={'h5'} color={'secondary.dark'}>Categories: </Typography></Grid>
            {cDis}
            <br></br>
            <Grid item><Typography variant={'h6'} color={'secondary.dark'}>Add Category: </Typography></Grid>
            <Grid item>
                <TextField
                    value={newCat}
                    onChange={(e) => {setNC(e.target.value)}}
                    label={'New Category Name'}
                    />
                <Button size={'small'} onClick={() => {handleNC()}}>
                    <Typography>Submit</Typography>
                </Button>
            </Grid>
            <br></br>
            <Grid item><Typography variant={'h6'} color={'secondary.dark'}>Remove Category: </Typography></Grid>
            <Grid item>
                <TextField
                    value={delCatID}
                    type={'number'}
                    //@ts-ignore
                    onChange={(e) => {setDelID(e.target.value)}}
                    label={'Id of category to delete'}
                    />
                <Button size={'small'} onClick={() => {handleDelCat()}}>
                    <Typography>Submit</Typography>
                </Button>
            </Grid>
        </Grid>
    )
}


