import {Grid, Typography, Link} from '@mui/material'
import {Header} from "../../viridis-ui";

export default function Rules() {
    let rules = [
        "All Participants must have been enrolled in a Computer Science course at UVM or be an active CS or " +
            "Data Science, Major or Minor during the 2023 calendar year (Jan to Dec).",
        "All Participants must be currently enrolled as a UVM student in the semester the CS Fair is being held.",
        ["Projects: ",
            <br/>,
            "- Group or individual projects are allowed. ",
            <br/>,
            "- Course projects are allowed (the more unique they are from your classmates',  " +
                "the higher your chances of getting in and winning). ",
            <br/>,
            "- Non course projects are highly encouraged (i.e., " +
                "something you did on your own).",
            <br/>,
            "- You may enter more than one project but you cannot present more than one " +
                "project in a time slot. It is your responsibility to confirm you have not been assigned to " +
                "present more than one project at a time. ",
            <br/>,
            "- Registration does not guarantee your project will be accepted.",
        ],
        "Code should be original or clearly documented if code is not yours.",
        "All entries must be present at their booth. We will provide space on a table, but you need to provide a laptop" +
            " (with a good battery, power should be available) and/or other display (e.g., poster).",
        "You are eligible to win only one competitive prize, you may forfeit a lower prize.",
        "Prize amounts may be reduced when there are less than 10 projects in a category as shown in " +
            "() next to the prize amount.",
        "Electronic gift cards will be be emailed to you the week after the CS Fair. If you do not recieve yours" +
            " notify the CS department within 2 weeks or forfeit your prize.",
        <Link
            href={"https://www.uvm.edu/sites/default/files/UVM-Policies/policies/prizes.pdf"}
            color={'secondary.main'}
        >UVM monetary prize policy
        </Link>,
        "All entries allow us to use photos and videos for promotional material.",
        "Participants will receive a free t-shirt (while supplies last, in order of registration).",
        "Violation of the rules will result in disqualification.",
        "Rules are subject to change.",
    ]

    return (
        <Grid
            container direction={'column'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            spacing={1}
            border={2}
            borderRadius={3}
            borderColor={'secondary.dark'}
            px={3}
            py={1.5}
        >
            <Grid container item alignItems={'center'} justifyContent={'center'}>
                <Header divider={true} variant={'h4'} color={'secondary.dark'}>
                    Rules
                </Header>
            </Grid>
            {rules.map((rule, index) => (
                <Grid container item direction={'row'} justifyContent={'flex-start'} key={index}>
                    <Grid item xs={1} md={.5}>
                        <Typography variant={'h6'} fontWeight={'light'} color={'white'}>
                            {index + 1}.
                        </Typography>
                    </Grid>
                    <Grid item xs={11} md={11.5}>
                        <Typography variant={'h6'} fontWeight={'light'} color={'white'}>
                            {rule}
                        </Typography>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}
