import {Accordion, AccordionSummary, AccordionDetails} from "@mui/material"
import {Typography,Box,Button, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {PropsWithChildren} from "react";
import { text } from "stream/consumers";

interface InfoProps {
    title:string
    info : Array<{label:string, info:any}>
}
/*
    This is an abstracted component to display resource information in a consistent fashion.
    Components with logic like api calls or callbacks should be inserted as child components
    by another wrapper that formats the object into the info prop and handles any logic.
 */
export default function OldInfoDisplay(props:PropsWithChildren<InfoProps>){
    let details = []
    for (let i of props.info){
        details.push(
            <Typography variant={'h6'} sx={{mt:1, mb:1, wordWrap:'break-word', wordBreak:'break-word'}}>
                <Sb>{i.label}:</Sb> {i.info}
            </Typography>
        )
    }

    return (
    <Accordion sx={{border:1, borderColor:'primary.main', borderRadius:4, width:'100%'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{borderBottom:1, borderColor:'primary.main', justifyContent:'flex-start', wordWrap:'break-word'}}
        >
            <Typography variant={'h5'} color={'primary.main'} fontWeight={'medium'} mr={10}>
              {props.title}
            </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{wordWrap:'break-word'}}>
            {details}
            {props.children}
        </AccordionDetails>
    </Accordion>
    )
}


function Sb(props:any) {
    return(
        <Typography variant={"inherit"} display={"inline"} fontWeight={'bold'} mr={1}>
            {props.children}
        </Typography>
    )
}
