import axios, {AxiosRequestConfig} from "axios";
import {stepIconClasses} from "@mui/material";

function createClient(url:string) {
    let client = axios.create({
        baseURL: url,
        timeout: 3000,
        headers: {
            'content-type': 'application/json'
        }
    })
    client.interceptors.response.use(
        (response) => {
            return response.data
        },
        (e) => { throw e }
    )
    return client
}

const localURL = 'http://localhost:5001/api/'
const labURL = 'https://amenezes.w3.uvm.edu/api/'
const prodURL = 'https://csfair.w3.uvm.edu/api/'
const urlMap : {[key: string]: string} = {
    "csfair.w3.uvm.edu" : prodURL,
    "amenezes.w3.uvm.edu": labURL,
    "localhost": localURL
}
let url = urlMap[window.location.hostname] ? urlMap[window.location.hostname] : urlMap["localhost"]
const client = createClient(url)

export async function get<T>(url:string, config?:AxiosRequestConfig): Promise<T> {
    return await client.get(url, config)
}
export async function post<T>(url:string, data:any, config?:AxiosRequestConfig): Promise<T> {
    return await client.post(url, data)
}
export async function put<T>(url:string, data?:any, config?:AxiosRequestConfig): Promise<T> {
    return await client.put(url, data)
}
export async function del<T>(url:string, data:any, config?:AxiosRequestConfig): Promise<T> {
    return await client.delete(url, data)
}

export async function login(data:{netid:string, password:string}) {
    return await client.post('/login', data)
}

interface SignUpReq {
    netid: string
    password:string
    name: string
    email: string
}
export const signup = async(userInfo:SignUpReq) => {
    return await post('signup', userInfo)
}

interface ProjectReq {
    title: string
    studentInfoList: string[]
    description: string
    groupSize: number
    year: number
    category: string
    postingNetid: string
    studentInfoRaw: string
}
export const createProject = async(data: ProjectReq) => {
    return await post<{success: true} | {success:false, reason:string}>('projects', data)
}

export const loginAdmin = async(data:{netid:string, password:string}) => {
    return await post('loginAdmin', data)
}

export const getAllProjects = async() => {
    return await get<Project[]>('projects')
}

export const setProjectApproval = async(data:{projectid:number, status:"approved"|"denied"}) => {
    return await post('updateProjectStatus', data)
}

export const getStudentProjects = async(netid:string) => {
    return await get('student_projects?netid='+netid)
}

export const getApprovedProjects = async() => {
    return await get('projects?approved=1')
}

export const getProjectStats = async() => {
    return await get<ProjectStats>('/project_stats')
}

export const assignBooth = async(booth:string, id:number  ) => {
    return await post<{success: true} | {success:false, reason:string}>('/assign_booth', {id:id, booth:booth})
}

export const changeCategory = async(category:string, id:number) => {
    return await post<{success: true} | {success:false, reason:string}>('/change_category', {id:id, category:category})
}
export const changeTS = async(ts:string, id:number) => {
    return await post<{success: true} | {success:false, reason:string}>('/change_ts', {id:id, ts:ts})
}

export const getWinningProjs = async() => {
    return await get<Project[]>('winners')
}

interface Category {
    id: number
    name: string
}
export const getCats = async() => {
    return await get<Category[]>('categories')
}

export const addCat = async(name:string) => {
    return await post<{success: true} | {success:false, reason:string}>('categories', {name:name})
}

export const delCat = async(id:number) => {
    console.log(id)
    return await put<{success: true} | {success:false, reason:string}>('categories', {id:id})
}
