import {Box, Grid, Typography, Grow, IconButton} from "@mui/material";
import {Header} from "../../viridis-ui";
import Divider from "@mui/material/Divider";
import CountdownTimer from "./CountdownTimer";
import CSLOGO from '../../assets/CS_LOGO.png'
import BannerIMG from "../../assets/uvm_davis_center--4-min.jpg"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
export default function HeroBanner(props:{setMenuOpen:Function, openMenu:boolean}) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    let showButton = (props.openMenu) ? "none" : "inherit"
    if (matches) {
        return (
        <Grid
            container
            item
            direction={'column'}
            sx={{
                display: {xs:"none", sm:"flex"},
                alignItems: "space-between",
                justifyContent: "center",
                width:'100%',
                height:'100%',
                flexDirection: "row",
                backgroundImage: `url(${BannerIMG})`,
                backgroundSize: "100%",
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}

        >
            <Grid
                container direction={'row'}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                spacing={0}
                height={'10vh'}
                pt={1}
            >
                <Grid item xs={4}>
                    <Grow in={true} style={{transitionDelay:'400ms'}}>
                    <Box
                        component={'img'}
                        src={CSLOGO}
                        alt={'UVM CS Dept'}
                        height={'10vh'}
                        width={'auto'}
                        maxWidth={'100%'}
                        pl={1}
                    />
                    </Grow>
                </Grid>
                <Grid
                    container item xs={7}
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <Grow in={true} style={{transitionDelay:'800ms'}}>
                    <Typography
                        fontFamily={'oswald'}
                        letterSpacing={'.1rem'}
                        textAlign={"center"}
                        variant={'h2'}
                        color={'secondary.main'}
                    >
                        The UVM CS Fair Begins In...
                    </Typography>
                    </Grow>
                </Grid>
            </Grid>
            <Grid
                container item direction={'column'}
                alignItems={'center'}
                justifyContent={'flex-end'}
            >
            <Grid
                container
                item
                direction={'row'}
                alignItems={'flex-start'}
                justifyContent={'center'}
                width={'100%'}
            >
                <CountdownTimer eventDate={"12-06-2024 1:00:00 PM"} />
            </Grid>
            <Grid
                container direction={'row'}
                justifyContent={'space-around'}
                alignItems={'flex-end'}
                mt={'8vh'}
            >
                <Grid item xs={5} >
                    <Grow in={true} style={{transitionDelay:'800ms'}}>
                    <Typography variant={'h5'} color={'white'} textAlign={'left'}>
                       1-5 PM, December 6, 2024
                    </Typography>
                    </Grow>
                </Grid>
                <Grid xs={5}
                    container
                    item
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                    textAlign={'center'}
                >
                    <Grid item>
                        <Grow in={true} style={{transitionDelay:'800ms'}}>
                        <Typography variant={'h5'} color={'white'}>
                            Grand Maple Ballroom
                        </Typography>
                        </Grow>
                    </Grid>
                    <Grow in={true} style={{transitionDelay:'800ms'}}>
                    <Divider
                        orientation={'vertical'}
                        flexItem
                        color={'#FFD416'}
                        sx={{
                            borderRightWidth:2.25,
                            borderRadius:1,
                            mx:1.35
                        }}
                    />
                    </Grow>
                    <Grid item>
                        <Grow in={true} style={{transitionDelay:'800ms'}}>
                        <Typography variant={'h5'} color={'white'}>
                            Davis Center
                        </Typography>
                        </Grow>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
        )
    }
    else {
        return (
            <Grid
                container
                item
                direction={'column'}
                alignItems={'space-between'}
                justifyContent={'center'}
                spacing={'5vh'}
            >
                <Grid item>
                    <Grow in={true} style={{transitionDelay:'800ms'}}>
                    <Typography
                        fontFamily={'oswald'}
                        letterSpacing={'.1rem'}
                        textAlign={"left"}
                        variant={'h2'}
                        color={'secondary.main'}
                    >
                        The UVM CS Fair Begins In...
                    </Typography>
                    </Grow>
                </Grid>
                <Grid item>
                    <CountdownTimer eventDate={"12-06-2024 1:00:00 PM"} />
                </Grid>
                <Grid
                    container
                    item
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'space-between'}

                >
                    <Grid item>
                        <Grow in={true} style={{transitionDelay:'800ms'}}>
                        <Typography variant={'h5'} color={'white'} textAlign={'left'}>
                           1-5 PM, December 8, 2023
                        </Typography>
                        </Grow>
                    </Grid>
                    <Grid item>
                        <Grow in={true} style={{transitionDelay:'800ms'}}>
                        <Typography variant={'h5'} color={'white'}>
                            Grand Maple Ballroom
                        </Typography>
                        </Grow>
                    </Grid>
                    <Grid item>
                        <Grow in={true} style={{transitionDelay:'800ms'}}>
                        <Typography variant={'h5'} color={'white'}>
                            Davis Center
                        </Typography>
                        </Grow>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

/*
<Grid item mt={2.5} ml={4}>
<Typography variant={'h6'}  textAlign={'left'} color={'white'}>
    The CS Fair is a UVM event which provides Computer Science students
    the opportunity to show-off projects from websites to robots and everything in between,
    and the chance to win $300 in prize money. Judged by a combination of alums,
    faculty, and sponsors, winners receive $300, while 2nd and 3rd
    place earn $200 and $100 respectively.<br/> Register now to stake a claim to the prize.
</Typography>
</Grid>
 */
