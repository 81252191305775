import {useState, useEffect, ReactNode} from "react";
import {getWinningProjs} from "../ApiClient";
import {useErrorBoundary} from "react-error-boundary";
import {Header, LoadingScreen} from "../viridis-ui";
import {Box, Card, CardActionArea, CardContent, Grid, Typography} from "@mui/material";
import Carousel from "../viridis-ui/Carousel";

export default function Winners() {
    const {showBoundary} = useErrorBoundary()
    const [winners, setWinners] = useState<Project[]>([])
    useEffect(() => {
        getWinners()
    }, [])

    const getWinners = () => {
        getWinningProjs().then((res)=> {
            //@ts-ignore
            setWinners(res)
        })
        .catch((e) => {showBoundary(e)})
    }

    let items:ReactNode[] = []
    winners.map((winner) => {
        items.push(
            <WinnerCard p={winner} />
        )
    })
    return (
        <Grid
            // outer container (central content)
            container width={'100%'}
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Header variant={'h3'} divider color={'secondary.dark'} mt={4}>
                2024 Winners
            </Header>
            <Carousel steps={winners.length} items={items} />
        </Grid>
    )

}

function WinnerCard({p}:{p:Project}) {
    let studentsList = p.students.split(',')
    let displayNames:string[] = []
    for (let i of studentsList) {
        if (!i.includes('@')) {displayNames.push(i)}
    }

    let d:string;
    let t = 1000
    if (p.description.length > t) {
        d = p.description.substring(0, t) + "..."
    }
    else {
        d = p.description
    }
    return(
        <Box sx={{display: "flex", justifyContent: "center", alignItems:'center'}}>
        <Card sx={{height:'60vh'}}>
            <CardContent>
                    <Typography variant={'h5'} mb={.5} color={'black'}>
                        {p.title}
                    </Typography>
                    <Typography variant={'h6'} color={'black'} fontWeight={'light'} mb={1}>
                        {p.category}
                    </Typography>
                    <Typography variant={'h6'} color={'black'} fontWeight={'light'} mb={1}>
                        {
                            (displayNames.length > 0)
                                ? displayNames.join()
                                : p.students
                        }
                    </Typography>
                    <Typography variant={'subtitle1'} color={'black'}>
                        {d}
                    </Typography>
                </CardContent>
        </Card>
        </Box>
    )
}
