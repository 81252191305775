import {Box, Grid, Typography, Link as MUILink} from "@mui/material";

export default function Footer() {
    let d = new Date()
    let year = d.getFullYear()
    return(
        <Box
            component="footer"
            mt={'auto'}
            bgcolor={'primary.dark'}
            py={1}
            fontWeight={'light'}
        >
        <Grid
            container
            direction={'row'}
            justifyContent={'center'}
            alignItems={'flex-end'}
        >
            <Grid item>
                <Typography
                    sx={{letterSpacing:'.1rem', mr:1.5, ml: 1.5}}
                    color={'white'}
                    variant={'subtitle1'}
                > © {year} &nbsp; The University of Vermont </Typography>
            </Grid>
            <Grid item>
                <MUILink
                    underline={'none'}
                    href={'https://www.uvm.edu/compliance/website-privacy-policy/terms-use'}
                >
                    <Typography
                        sx={{
                            letterSpacing: '.1rem',
                            "&:hover": {color:'secondary.main'},
                            mr: 2, ml:2
                        }}
                        color={'white'}
                        variant={'subtitle1'}
                    > Terms of Use </Typography>
                </MUILink>
            </Grid>
            <Grid item>
                <MUILink
                    underline='none'
                    href={'https://www.uvm.edu/aaeo/americans-disabilities-act-ada-and-reasonable-accommodations'}
                >
                    <Typography
                        sx={{
                            letterSpacing: '.1rem',
                            "&:hover": {color:'secondary.main'},
                            mr: 2, ml:2
                        }}
                        color={'white'}
                        variant={'subtitle1'}
                    > Accessibility </Typography>
                </MUILink>
            </Grid>
            <Grid item>
                <MUILink
                    underline='none'
                    href={'https://www.linkedin.com/in/arimenezes/'}
                >
                    <Typography
                        sx={{
                            letterSpacing: '.1rem',
                            "&:hover": {color:'secondary.main'},
                            mr: 2, ml:2
                        }}
                        color={'white'}
                        variant={'subtitle1'}
                    > Site Creator </Typography>
                </MUILink>
            </Grid>
        </Grid>
        </Box>
    )
}
