import {Grid, Tab, Tabs, Typography} from '@mui/material'
import CreateProject from "./CreateProject";
import {useEffect, useState} from "react";
import MyProjs from './MyProjs'
import {useAuth} from "../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
export default function Dashboard() {
    const [view, setView] = useState<"createProj"|"myProjs">("myProjs")
    const {auth} = useAuth()
    const nav = useNavigate()
    useEffect(() => {
        if (auth.netid === '' || auth.userType === undefined) {
        nav('/login')
    }
    console.log(auth.netid)
    }, [])

    return (
        <Grid container direction={'column'} alignItems={'center'} justifyContent={'flex-start'}>
            <Grid item xs={12} mt={4.5}>
                <Tabs value={view} onChange={(e, val) => {setView(val)}} centered>
                    <Tab label={<Typography color="white"  variant={'h5'}>My Projects</Typography>}
                         value={"myProjs"}/>
                    <Tab
                        label={<Typography color="white" variant={'h5'}>Submit Project</Typography>}
                        value={"createProj"}
                    />
                </Tabs>
            </Grid>
            {(view === "createProj")?<CreateProject /> : <MyProjs/> }

        </Grid>
    )
}

